import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
// import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
// import PreChorus from "../../components/PreChorus.js";
import Verse from "../../components/Verse.js";

export default function WrongSideOfTown() {
  return (
    <Layout>
      <Helmet title="Wrong Side of Town | Michael Weslander" />

      <h1>Wrong Side of Town</h1>
      <h2>Michael Weslander</h2>
      <h3>Key: E</h3>
      <h4>Tempo: 80</h4>
      <Link to="/">List</Link>

      <Columns columns="3">
        <Verse>
          <p>I don't suppose I can complain</p>
          <p>This is the road I chose to stumble down</p>
          <p>It gets a little lonely</p>
          <p>But I know I ain't the only one</p>
          <p>At the lost and found</p>
          <p>And sure enough she's watching</p>
          <p>And hoping that I've grown enough</p>
          <p>To settle down</p>
        </Verse>

        <Chorus>
          <p>But I'm looking</p>
          <p>For a piece of my heart</p>
          <p>On the wrong side of town</p>
        </Chorus>

        <Verse>
          <p>Resolve is quickly freezing</p>
          <p>And the microwave is screaming</p>
          <p>Down the hall</p>
          <p>I know what she's thinking</p>
          <p>Even thought she barely knows</p>
          <p>Me at all</p>
          <p>By the end of the afternoon</p>
          <p>I've made sure these walls</p>
          <p>I've been building up</p>
          <p>Are 100 feet tall</p>
        </Verse>

        <Chorus>
          <p>And go back to searching</p>
          <p>For a piece of my heart</p>
          <p>On the wrong side of town</p>
        </Chorus>

        <Bridge>
          <p>I've been there baby</p>
          <p>So know that I'm not trying to be the one</p>
          <p>Who lets you down</p>
          <p>I'm not proud of this</p>
          <p>String of broken hearts</p>
          <p>I carry around</p>
        </Bridge>

        <Bridge>
          <p>But if we believe that time can heal</p>
          <p>If we believe that a soul can mend</p>
          <p>If we believe that the sun is coming</p>
          <p>I believe I can see it up around the bend</p>
          <p>If we believe that the moral of the story</p>
          <p>Is believing that we can work it out as friends</p>
          <p>Believe me baby when I say to you</p>
          <p>Change doesn't have to be an end</p>
          <p>Change doesn't have to be an end</p>
        </Bridge>

        <Verse>
          <p>Can't you see I'm terrible</p>
          <p>At telling her</p>
          <p>That I still care</p>
          <p>I'm mixing up my signals</p>
          <p>I can tell this</p>
          <p>In her longing stare</p>
          <p>I guess that I'll just resort</p>
          <p>To the part</p>
          <p>Where I don't say</p>
          <p>Anything at all</p>
        </Verse>

        <Chorus>
          <p>And go back to searching</p>
          <p>For a piece of my heart</p>
          <p>On the wrong side of town</p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
